import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-x-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_RadioButton, {
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue = $event)),
        _ctx.onChange
      ],
      id: _ctx.value,
      value: _ctx.value,
      label: _ctx.label
    }, null, 8, ["modelValue", "id", "value", "label", "onUpdate:modelValue"]),
    _createVNode("label", {
      for: _ctx.value,
      class: "cursor-pointer"
    }, _toDisplayString(_ctx.label), 9, ["for"])
  ]))
}