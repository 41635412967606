
import { Options, prop, Vue } from "vue-class-component";
import RadioButton from "primevue/radiobutton";

class Props {
  modelValue = prop<boolean>({
    default: false,
    type: Boolean
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  value = prop<string>({
    default: "",
    type: String
  });
  label = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  emits: ["update:modelValue"],
  components: {
    RadioButton
  }
})
export default class LpRadioButton extends Vue.with(Props) {
  onChange(value: string) {
    this.$emit("update:modelValue", value);
  }
}
